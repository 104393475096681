import { TELEGRAM_URL } from '~/common/constants';

export const REQUIRED_FIELD = 'Поле не должно быть пустым';

export const validateRequiredField = <T = string>(toValidate: T): string | true => {
  if (!toValidate) {
    return REQUIRED_FIELD;
  }

  return true;
};

export const validateTelegramUrl = (toValidate: string): string | true => {
  if (TELEGRAM_URL.test(toValidate)) {
    return true;
  }

  return 'Ссылка для кнопки должна быть формата: "https://t.me/ ..."';
};
